import React, { Fragment, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box, Stack } from '@mui/material';
import { MdOutlineDone } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import EmptyTableMessage from '../../HOC/FunctionComponents/EmptyTableMessage';

export const batchFields = [
    "id",
    { barcodes: ["id", "code"] },
    {
        customer: [
            "code",
            {
                operation: `isMobileHidden(code: WAYBILL)`,
                fields: [],
                variables: {
                },
            },],
    },
];

export const shipmentFields = [
    {
        data: [
            "code",
            "recipientName",
            "recipientAddress",
            "recipientMobile",
            "recipientPhone",
            "description",
            "weight",
            "piecesCount",
            "totalAmount",
            "senderName",
            "senderPhone",
            "senderMobile",
            "refNumber",
            "notes",
            "date",
            {
                type: ["name", "code"],
            },
            {
                originBranch: ["name"],
            },
            {
                customer: [
                    "code",
                    {
                        operation: `isMobileHidden(code: WAYBILL)`,
                        fields: [],
                        variables: {
                        },
                    },
                ],
            },
            {
                openable: ["code"],
            },

            {
                recipientZone: ["name"],
            },
            {
                recipientSubzone: ["name"],
            },
            {
                senderZone: ["name"],
            },
            {
                senderSubzone: ["name"],
            },
        ],
    },
];

const PREFIX = "SHIPMENT_STICKY_PRINT";

export const classes = {
    printSticky: `${PREFIX}-printSticky`,
    stickyFontSize: `${PREFIX}-stickyFontSize`,
    centerContent: `${PREFIX}-centerContent`,
    barcode: `${PREFIX}-barcode`,
    line: `${PREFIX}-line`,
    tableData: `${PREFIX}-tableData`,
    width50: `${PREFIX}-width50`,
    borderRight: `${PREFIX}-borderRight`,
    padding3: `${PREFIX}-padding3`,
    padding1: `${PREFIX}-padding1`,
    notes: `${PREFIX}-notes`,
    recipientAddress: `${PREFIX}-recipientAddress`,

};
export const Root = styled("div")(({ theme }) => ({
    [`& .${classes.printSticky}`]: {
        pageBreakAfter: "always",
        pageBreakInside: "avoid",
        maxWidth: "7cm",
        maxHeight: "9cm",
        overflow: "hidden",
        fontSize: 11,
        color: "black",
        padding: "0 2px"
    },
    [`& .${classes.stickyFontSize}`]: {
        fontSize: "9px",
    },
    [`& .${classes.line}`]: {
        borderBottom: "1px solid #0000002b",
    },

    [`& .${classes.borderRight}`]: {
        borderRight: "1px solid #0000002b",
    },

    [`& .${classes.width50}`]: {
        width: "50%"
    },
    [`& .${classes.tableData}`]: {
        width: "calc(100% * (1/4))",
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        textAlign: "center",
        padding: "4px 0",
        height: "100%",
        justifyContent: 'space-between'
    },
    [`& .${classes.padding3}`]: {
        padding: "3px 0"
    },
    [`& .${classes.padding1}`]: {
        padding: "1px 0"
    },

    [`& .${classes.barcode}`]: {
        fontFamily: "'Libre Barcode 39 Text'",
        fontSize: 30,
    },
    [`& .${classes.notes}`]: {
        WebkitLineClamp: 6,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        display: "-webkit-box !important",
        lineHeight: 1.1,
        // minHeight: "27px",
    },
    [`& .${classes.recipientAddress}`]: {
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        display: "-webkit-box !important"
    },
}));

function createDynamicStyles() {
    const style = document.createElement('style');
    style.type = 'text/css';

    const styles = `
    @page {
        size: 7cm 9cm; 
        margin: 1mm !important;
        overflow: hidden;
    }
    * {
            color: black;
    }
    body {
            background: #fff !important
    }
    `;

    if (style.styleSheet) {
        style.styleSheet.cssText = styles;
    } else {
        style.appendChild(document.createTextNode(styles));
    }

    document.head.appendChild(style);
}

const Template7X9 = (props) => {
    const {
        loading,
        validData,
        settingsLoad,
        parsedData,
        isBatch,
        footer,
        currency,
        LogoImg
    } = props

    const { t } = useTranslation()

    useEffect(() => {
        createDynamicStyles()
    }, [])

    return (
        <Fragment>
            {loading || (!loading && !validData) || settingsLoad ? (
                <>
                    <EmptyTableMessage
                        loading={loading}
                        message={t("noShipmentWithCode")}
                    />

                    {/* <FullScreenLoading minHeight="25%" /> */}
                </>
            ) : (
                parsedData.map((shipment, index) => {
                    const rtsShipment = shipment?.type?.code === "RTS";
                    return (
                        <Root key={index}>
                            <div
                                className={clsx({
                                    [classes.printSticky]: !loading && validData,
                                })}
                                style={{ height: "100%", lineHeight: "1.2" }}
                            >
                                <Stack
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    direction={"row"}
                                    className={classes.line}
                                    height={"1cm"}
                                >
                                    <div className={classes.width50}>
                                        <LogoImg forceLight={true} className={classes.logoImg} />
                                    </div>
                                    <Stack justifyContent={"center"} alignItems={"center"} className={classes.width50}>
                                        <div
                                            style={{
                                                width: "auto",
                                            }}
                                        >
                                            {shipment?.type?.name}
                                        </div>
                                    </Stack>
                                </Stack>
                                <Stack
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    height={"1cm"}
                                    className={clsx(
                                        classes.barcode,
                                        classes.line
                                    )}
                                >
                                    *{shipment.code}*
                                </Stack>
                                <Stack
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    direction={"row"}
                                    height={"1cm"}
                                    className={clsx(
                                        classes.line,
                                        classes.stickyFontSize,
                                    )}
                                >
                                    <div className={clsx(classes.tableData, classes.borderRight)}>
                                        <Box component="span" >
                                            {t("weight")}
                                        </Box>
                                        <div>{shipment?.weight}</div>
                                    </div>
                                    <div className={clsx(classes.tableData, classes.borderRight)}>
                                        <Box component="span" >
                                            {t("collection")}
                                        </Box>
                                        <div>
                                            <Box component="span">
                                                {rtsShipment ? 0 : shipment?.totalAmount}
                                            </Box>
                                            {!isBatch && <Box component="span" pl="2px">
                                                {currency}
                                            </Box>}
                                        </div>
                                    </div>
                                    <div className={clsx(classes.tableData, classes.borderRight)}>
                                        <Box component="span">
                                            {t("open")}
                                        </Box>
                                        <div>
                                            {!isBatch && (shipment?.openable?.code === "Y" ? (<MdOutlineDone />) : (<AiOutlineClose />))}
                                        </div>
                                    </div>
                                    <div className={clsx(classes.tableData)}>
                                        <Box component="span" >
                                            {t("piece")}
                                        </Box>
                                        <div>{shipment?.piecesCount}</div>
                                    </div>
                                </Stack>

                                <Box
                                    height={"2cm"}
                                    className={clsx(
                                        classes.line,
                                        classes.padding3,
                                        classes.stickyFontSize,
                                    )}
                                >
                                    <div>
                                        <span style={{ fontWeight: "bolder" }}>
                                            {t("recipient") + ": "}
                                        </span>{shipment?.recipientName}</div>
                                    <div
                                        className={clsx(classes.padding1)}
                                    >
                                        {isBatch ? ' ' : (shipment?.recipientMobile &&
                                            shipment?.recipientPhone
                                            ? `${shipment?.recipientMobile} - ${shipment?.recipientPhone}`
                                            : shipment?.recipientMobile ??
                                            shipment?.recipientPhone)}
                                    </div>
                                    <div
                                        className={clsx(classes.padding1, classes.recipientAddress)}
                                    >
                                        {shipment?.recipientAddress}
                                    </div>
                                    <div
                                        className={clsx(classes.padding1
                                        )}
                                    >
                                        {!isBatch && (shipment?.recipientZone?.name +
                                            " - " +
                                            shipment?.recipientSubzone?.name)}
                                    </div>
                                </Box>
                                <Box
                                    height={"1.2cm"}
                                    className={clsx(
                                        classes.line,
                                        classes.padding3,
                                        classes.stickyFontSize,
                                    )}
                                >
                                    <div>
                                        <span style={{ fontWeight: "bolder" }}>
                                            {t("sender") + ": "}
                                        </span> {shipment?.senderName}
                                    </div>
                                    {!shipment?.customer?.isMobileHidden &&
                                        <div className={classes.padding1}>
                                            {shipment?.senderMobile && shipment?.senderPhone
                                                ? `${shipment?.senderMobile} - ${shipment?.senderPhone}`
                                                : shipment?.senderMobile ??
                                                shipment?.senderPhone}
                                        </div>
                                    }
                                    <div className={clsx(classes.padding1)}>
                                        {!isBatch && (shipment?.senderZone?.name +
                                            " - " +
                                            shipment?.senderSubzone?.name)}
                                    </div>
                                </Box>

                                <Stack
                                    height={"0.5cm"}
                                    justifyContent={"center"}
                                    className={clsx(
                                        classes.line,
                                        classes.stickyFontSize,
                                    )}
                                >
                                    <div>
                                        <span style={{ fontWeight: "bolder" }}>
                                            {t("refNumber") + ": "}
                                        </span>
                                        {shipment?.refNumber}
                                    </div>
                                </Stack>
                                <Box
                                    height={"1.8cm"}
                                    sx={{ py: "2px" }}
                                    className={clsx(
                                        classes.line,
                                        classes.stickyFontSize,
                                    )}
                                >
                                    <Box className={classes.notes}>
                                        <span style={{ fontWeight: "bolder" }}>{t("notes") + ": "}</span>{shipment?.notes}
                                    </Box>
                                </Box>
                                <Stack
                                    height={"0.3cm"}
                                    alignItems={"center"}
                                    className={classes.stickyFontSize}
                                >
                                    <Box component="span" pl="2px">
                                        {footer}
                                    </Box>
                                </Stack>
                            </div>
                        </Root>
                    )
                })
            )}
        </Fragment>
    )
}

export default Template7X9