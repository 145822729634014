import React, { Fragment, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import QRCode from 'react-qr-code';
import { Stack, Typography } from '@mui/material';
import EmptyTableMessage from '../../HOC/FunctionComponents/EmptyTableMessage';
import { dateFormat } from '../../../helpers/dateFunctions';

export const batchFields = [
    "id",
    { barcodes: ["id", "code"] },
    {
        customer: [
            "code",
            "name",
            {
                operation: `isMobileHidden(code: WAYBILL)`,
                fields: [],
                variables: {
                },
            },
            {
                zone: ["id", "code", "name"]
            },
            {
                subzone: ["id", "code", "name"]
            },
            "phone",
            "mobile"
        ],
    },
];

export const shipmentFields = [
    {
        data: [
            "code",
            "recipientName",
            "recipientAddress",
            "recipientMobile",
            "recipientPhone",
            "description",
            "weight",
            "piecesCount",
            "totalAmount",
            "senderName",
            "senderPhone",
            "senderMobile",
            "refNumber",
            "notes",
            "date",
            {
                type: ["name", "code"],
            },
            {
                originBranch: ["name"],
            },
            {
                customer: [
                    "code",
                    {
                        operation: `isMobileHidden(code: WAYBILL)`,
                        fields: [],
                        variables: {
                        },
                    },
                ],
            },
            {
                openable: ["code"],
            },

            {
                recipientZone: ["name"],
            },
            {
                recipientSubzone: ["name"],
            },
            {
                senderZone: ["name"],
            },
            {
                senderSubzone: ["name"],
            },
        ],
    },
];

const PREFIX = "SHIPMENT_STICKY_PRINT";

export const classes = {
    header: `${PREFIX}-header`,
    stickyCode: `${PREFIX}-stickyCode`,
    line: `${PREFIX}-line`,
    logoImg: `${PREFIX}-logoImg`,
    direction: `${PREFIX}-direction`,
    bold: `${PREFIX}-bold`,
    fromStyle: `${PREFIX}-fromStyle`,
    toStyle: `${PREFIX}-toStyle`,
    borderRight: `${PREFIX}-borderRight`,
    capitalize: `${PREFIX}-capitalize`,
    hideSenderPhone: `${PREFIX}-hideSenderPhone`,
    customFontSize: `${PREFIX}-customFontSize`,
    printSticky: `${PREFIX}-printSticky`,
    rotate: `${PREFIX}-rotate`,
    shipmentDetails: `${PREFIX}-shipmentDetails`,
    tableData: `${PREFIX}-tableData`,
    refNumber: `${PREFIX}-refNumber`,
    customWidth: `${PREFIX}-customWidth`,
    notes: `${PREFIX}-notes`,
    footer: `${PREFIX}-footer`,
    customLines: `${PREFIX}-customLines`,
};
export const Root = styled("div")(({ theme }) => ({
    [`& .${classes.printSticky}`]: {
        border: "1px solid #000",
        pageBreakAfter: "always",
        position: "relative",
        pageBreakInside: "avoid",
        width: "calc(10cm - (2px + 1mm))",
        height: "calc(10cm - (2px + 2mm))",
        overflow: "hidden",
        fontSize: 11,
        color: "black",
        boxSizing: "border-box",
        lineHeight: "1.2",
    },
    [`& .${classes.header}`]: {
        height: "1.9cm"
    },
    [`& .${classes.logoImg}`]: {
        maxHeight: "55%",
        maxWidth: "fit-content"
    },
    [`& .${classes.direction}`]: {
        height: "0.6cm"
    },
    [`& .${classes.bold}`]: {
        fontWeight: 700,
        [`& p`]: {
            fontWeight: 700,
        },
    },
    [`& .${classes.fromStyle}`]: {
        height: "1.4cm"
    },
    [`& .${classes.toStyle}`]: {
        height: "1.9cm"
    },
    [`& .${classes.capitalize}`]: {
        textTransform: "capitalize"
    },
    [`& .${classes.borderRight}`]: {
        borderRight: "1px solid #000",
    },
    [`& .${classes.customFontSize}`]: {
        fontSize: "10px",
        [`& p`]: {
            fontSize: "10px"
        },
    },
    [`& .${classes.hideSenderPhone}`]: {
        display: "none !important",
    },
    [`& .${classes.stickyCode}`]: {
        fontFamily: "'Libre Barcode 39 Text'",
        // height: "100px",
        overflow: "hidden",
        fontSize: 45,
        height: "1.4cm"
    },
    [`& .${classes.rotate}`]: {
        rotate: "-90deg",
        fontSize: "11px",
    },
    [`& .${classes.shipmentDetails}`]: {
        height: "0.6cm"
    },
    [`& .${classes.tableData}`]: {
        width: "calc(100% * (1/3))",
    },
    [`& .${classes.refNumber}`]: {
        height: "0.6cm"
    },
    [`& .${classes.customWidth}`]: {
        width: "7%"
    },
    [`& .${classes.notes}`]: {
        height: "0.9cm",
    },
    [`& .${classes.footer}`]: {
        position: "absolute",
        bottom: 0,
        width: "100%"
    },
    [`& .${classes.line}`]: {
        borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    [`& .${classes.customLines}`]: {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 2, /* number of lines to show */
        lineClamp: 2,
        WebkitBoxOrient: "vertical",
    },
}));


function createDynamicStyles() {
    const style = document.createElement('style');
    style.type = 'text/css';

    const styles = `
    @page {
        size: 10cm 10cm; 
        margin: 1mm !important;
        overflow: hidden;
    }
    * {
            color: black;
    }
    body {
            background: #fff !important
    }
    `;

    if (style.styleSheet) {
        style.styleSheet.cssText = styles;
    } else {
        style.appendChild(document.createTextNode(styles));
    }

    document.head.appendChild(style);
}

const Template10X10 = (props) => {
    const {
        loading,
        validData,
        settingsLoad,
        parsedData,
        isBatch,
        customer,
        footer,
        currency,
        LogoImg
    } = props

    const { t } = useTranslation()

    const getSenderMobiles = (shipment) => {
        return customer ? customer?.mobile && customer?.phone
            ? `${customer?.mobile} - ${customer?.phone}`
            : customer?.mobile ??
            customer?.phone :
            shipment?.senderMobile && shipment?.senderPhone
                ? `${shipment?.senderMobile} - ${shipment?.senderPhone}`
                : shipment?.senderMobile ??
                shipment?.senderPhone
    }

    useEffect(() => {
        createDynamicStyles()
    }, [])

    return (
        <Fragment>
            {loading || (!loading && !validData) || settingsLoad ? (
                <>
                    <EmptyTableMessage
                        loading={loading}
                        message={t("noShipmentWithCode")}
                    />
                </>
            ) : (
                parsedData.map((shipment, index) => {
                    const rtsShipment = shipment?.type?.code === "RTS";
                    return (
                        <Root key={index}>
                            <div
                                className={clsx({
                                    [classes.printSticky]: !loading && validData,
                                })}
                                style={{ lineHeight: "1.2 !important" }}
                            >
                                {/* Header */}
                                <Stack
                                    justifyContent={"space-between"}
                                    direction={"row"}
                                    px={1}
                                    className={clsx(
                                        classes.line,
                                        classes.header,
                                        classes.bold
                                    )}
                                >
                                    <Stack
                                        width={"60%"}
                                        pt={0.5}
                                        height={"100%"}
                                        fontWeight={"bold"}
                                        spacing={0.5}
                                    >
                                        <LogoImg forceLight={true} className={classes.logoImg} />
                                        <Typography className={classes.bold} variant='body2' textTransform={"capitalize"}>
                                            {t("printDate")} : {dateFormat(shipment?.date)}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        py={0.5}
                                        height={"100%"}
                                        width={"17%"}
                                        direction={"row"}
                                    >
                                        <QRCode size={900} value={shipment.code} style={{ height: "100%" }} />
                                    </Stack>
                                </Stack>
                                {/* End Header */}

                                {/* Direction */}
                                <Stack
                                    direction={"row"}
                                    className={clsx(
                                        classes.line,
                                        classes.direction,
                                        classes.bold
                                    )}
                                    justifyContent={"center"}
                                >
                                    <Stack
                                        className={clsx(classes.borderRight, classes.bold)}
                                        direction={"row"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        width={"100%"}
                                        spacing={0.2}
                                    >
                                        <Typography variant='body2'>
                                            {t("origin")}{": "}
                                        </Typography>
                                        <Typography variant='body2'>
                                            {!isBatch && (shipment?.senderZone?.name)}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        className={classes.bold}
                                        direction={"row"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        width={"100%"}
                                        spacing={0.2}
                                    >
                                        <Typography variant='body2'>
                                            {t("destination")}{": "}
                                        </Typography>
                                        <Typography variant='body2'>
                                            {!isBatch && (shipment?.senderZone?.name)}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                {/* End Direction */}

                                {/* Sender */}
                                <Stack
                                    direction={"row"}
                                    overflow={"hidden"}
                                    spacing={1}
                                    className={clsx(
                                        classes.line,
                                        classes.fromStyle,
                                        classes.bold
                                    )}
                                >
                                    <Stack
                                        className={clsx(classes.borderRight, classes.customWidth)}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                    >
                                        <Typography className={clsx(classes.rotate, classes.capitalize)}>
                                            {t("from")}
                                        </Typography>
                                    </Stack>
                                    <Stack spacing={0.2} py={0.3} width={"92%"}>
                                        <Typography className={classes.customFontSize}>{customer ? customer.name : shipment?.senderName}</Typography>
                                        <Typography className={clsx(classes.customFontSize, { [classes.hideSenderPhone]: customer ? customer.isMobileHidden : shipment?.customer?.isMobileHidden })}>
                                            {getSenderMobiles(shipment)}
                                        </Typography>
                                        <Typography className={classes.customFontSize}>
                                            {isBatch ? customer ? (customer?.zone?.name + " - " + customer?.subzone?.name) : "" : (shipment?.senderZone?.name + " - " + shipment?.senderSubzone?.name)}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                {/* End Sender */}

                                {/* Recipient */}
                                <Stack
                                    direction={"row"}
                                    overflow={"hidden"}
                                    spacing={1}
                                    className={clsx(
                                        classes.line,
                                        classes.toStyle,
                                        classes.bold
                                    )}
                                >
                                    <Stack
                                        className={clsx(classes.borderRight, classes.customWidth)}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                    >
                                        <Typography className={clsx(classes.rotate, classes.capitalize)}>
                                            {t("to")}
                                        </Typography>
                                    </Stack>
                                    <Stack spacing={0.2} width={"92%"} py={0.3}>
                                        <Typography className={classes.customFontSize}>{shipment?.recipientName}</Typography>
                                        <Typography className={classes.customFontSize}>
                                            {isBatch ? ' ' : (shipment?.recipientMobile &&
                                                shipment?.recipientPhone
                                                ? `${shipment?.recipientMobile} - ${shipment?.recipientPhone}`
                                                : shipment?.recipientMobile ??
                                                shipment?.recipientPhone)}
                                        </Typography>
                                        <Typography className={classes.customFontSize}>
                                            {!isBatch && (shipment?.recipientZone?.name +
                                                " - " +
                                                shipment?.recipientSubzone?.name)}
                                        </Typography>
                                        <Typography className={classes.customFontSize}>
                                            {shipment?.recipientAddress}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                {/* End recipient */}

                                {/* Barcode */}
                                <Stack
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    className={clsx(classes.stickyCode, classes.line)}
                                >
                                    *{shipment.code}*
                                </Stack>
                                {/* End Barcode */}

                                {/* Shipment Details */}
                                <Stack
                                    direction={"row"}
                                    className={clsx(
                                        classes.line,
                                        classes.bold,
                                        classes.shipmentDetails
                                    )}
                                >
                                    <Stack
                                        direction={"row"}
                                        spacing={0.3}
                                        px={0.3}
                                        alignItems={"center"}
                                        className={clsx(classes.tableData, classes.borderRight)}
                                    >
                                        <Typography className={classes.customFontSize} textTransform={"uppercase"}>
                                            {t("cod")}{":"}
                                        </Typography>
                                        <Stack direction={"row"} spacing={0.3}>
                                            <Typography className={classes.customFontSize}>
                                                {rtsShipment ? 0 : shipment?.totalAmount}
                                            </Typography>
                                            <Typography className={classes.customFontSize}>
                                                {!isBatch && currency}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        direction={"row"}
                                        spacing={0.3}
                                        px={0.3}
                                        alignItems={"center"}
                                        className={clsx(classes.tableData, classes.borderRight)}
                                    >
                                        <Typography className={classes.customFontSize} textTransform={"uppercase"}>
                                            {t("pieces")}{":"}
                                        </Typography>
                                        <Typography className={classes.customFontSize}>
                                            {shipment?.piecesCount}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction={"row"}
                                        spacing={0.3}
                                        px={0.3}
                                        alignItems={"center"}
                                        className={classes.tableData}
                                    >
                                        <Typography className={classes.customFontSize} textTransform={"uppercase"}>
                                            {t("weight")}{":"}
                                        </Typography>
                                        <Typography className={classes.customFontSize}>
                                            {shipment?.weight}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                {/* End Shipment Details */}

                                {/* Ref Number */}
                                <Stack direction={"row"} p={0.3} spacing={0.3} className={clsx(
                                    classes.line,
                                    classes.refNumber,
                                    classes.bold,
                                    classes.customFontSize,
                                )}>
                                    <Typography className={classes.capitalize}>{t("refNumber")}:</Typography>
                                    <Typography className={classes.capitalize}>{shipment?.refNumber}</Typography>
                                </Stack>
                                {/* End Ref Number */}

                                {/* Notes */}
                                <Stack
                                    direction={"row"}
                                    p={0.3}
                                    spacing={0.3}
                                    overflow={"hidden"}
                                    className={clsx(
                                        classes.line,
                                        classes.notes,
                                        classes.bold,
                                        classes.customFontSize,
                                    )}
                                >
                                    <Typography className={classes.capitalize}>{t("notes")}:</Typography>
                                    <Typography className={clsx(classes.capitalize,classes.customLines)}>{shipment?.notes}</Typography>
                                </Stack>
                                {/* End Notes */}
                                <Stack
                                    overflow={"hidden"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    className={clsx(
                                        classes.bold,
                                        classes.footer,
                                        classes.customFontSize
                                    )}
                                >
                                    <Typography>
                                        {footer}
                                    </Typography>
                                </Stack>
                            </div>
                        </Root>
                    )
                })
            )}
        </Fragment>
    )
}

export default Template10X10