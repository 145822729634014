import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { FilterList, ViewColumn } from "@mui/icons-material";
import SearchTable from "../ShipmentSearch/SearchTable";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import ShipmentColumView from "../ShipmentSearch/shipmentColumView"
import { defaultQueryFields, initialData, shipmentFieldsFun } from "../ShipmentSearch/ListShipmentTableCells";

// const PREFIX = "ShipmentsInWarehouse";


// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
}));

// const drawerWidth = 240;

const DeletedShipments = (props) => {
  const [keys, setKeys] = useState(localStorage.getItem("deleted-shipments") ? JSON.parse(localStorage.getItem("deleted-shipments")) : initialData["deleted-shipments"])

  const user = Globals.user;
  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );

  const [shipmentViewColum, setOpenShipmentViewColum] = useState(false);
  const openColumView = () => {
    setOpenShipmentViewColum(true)
  };

  const defaultPageInput = {}
  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const [checkedIds, setCheckedIds] = useState([]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmitFunc = () => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
  };

  const clearCheckBox = () => setCheckedIds([]);

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
      color: "default"
    },
    {
      id: "ViewColumn",
      title: "viewColumns",
      action: openColumView,
      icon: ViewColumn,
    },
  ]

  return (
    <Root>

      <ShipmentColumView
        openViewColum={shipmentViewColum}
        setOpenViewColum={setOpenShipmentViewColum}
        localStorageName={"deleted-shipments"}
        initialData={keys}
        setKeys={setKeys}
        shipmentFields={shipmentFieldsFun(adminNotesPermission)}
      />

      <SearchTable
        queryFields={defaultQueryFields(keys, adminNotesPermission)}
        setCheckedIds={(ids) => setCheckedIds(ids)}
        checkedIds={checkedIds}
        withCheckAll
        icons={icons}
        path={props.match.path}
        drawerState={drawerState[filterAnchor]}
        keys={keys}
        operationName={"listDeletedShipments"}
        onSubmitFunc={onSubmitFunc}
        defaultPageInput={defaultPageInput}
        clearCheckBox={clearCheckBox}
        pathname="deleted-shipments"
        filtersAllowed={[]}
        removeCode
        notSumShipments
        shipmentLink="deleted-shipments"
        variablesTypes={
          { search: "String" }
        }
      />
    </Root>
  );
};

export default DeletedShipments;
